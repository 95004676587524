<template>
  <div class="container">
    <div class="d-flex justify-content-between mb-3 align-items-center">
      <h1 class="m-0 mb-2">Consents</h1>
      <router-link
        to="/admin/consents/create?src=consents"
        class="btn btn-outline-blue py-2"
      >
        <icon type="plus" /> Add New Consent
      </router-link>
    </div>

    <alert v-if="consentsLoading" class="light-shadow" />
    <alert
      v-else-if="consents.length == 0"
      :hideLoader="true"
      class="light-shadow"
    >
      No consents added yet.
    </alert>
    <div
      class="
        card
        mb-0
        theme-card
        mobile-tablet-no-card
        p-2 p-md-0
        table-container
        border-lg-0
      "
      v-else
    >
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="consent in consents" :key="consent.id">
            <td><b class="mobile-tablet-only mr-2">ID:</b>{{ consent.id }}</td>
            <td>
              <b class="mobile-tablet-only mr-2">Title:</b>
              {{ consent.title }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Description:</b>
              <button class="btn btn-theme" @click="showDescription(consent)">
                <icon type="eye fa-lg" />
              </button>
            </td>
            <td class="td-actions">
              <div class="d-flex justify-content-lg-end justify-content-center">
                <router-link
                  :to="`/admin/consents/${consent.id}?src=consents`"
                  class="btn btn-sm btn-black"
                >
                  Edit
                </router-link>
                <button
                  class="btn btn-danger btn-sm ml-3"
                  @click="deleteConsentHandler(consent)"
                >
                  <i
                    class="fas fa-spin fa-circle-notch"
                    v-if="deleteConsentLoading == consent.id"
                  ></i>
                  <icon v-else type="trash-alt" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "Consents",
  mounted() {
    this.getConsents();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      consents: (state) => state.consents.consents.data,
      consentsLoading: (state) => state.consents.consents.loading,
      deleteConsentLoading: (state) => state.consents.deleteConsentLoading,
    }),
  },
  methods: {
    ...mapActions({
      getConsents: "consents/getConsents",
      deleteConsent: "consents/deleteConsent",
    }),
    deleteConsentHandler: function (consent) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const success = await vm.deleteConsent(consent.id);
            if (success) {
              vm.getConsents();
            }
          }
        });
    },
    showDescription: function (consent) {
      const description = consent?.description || "No description available";
      Swal.fire({
        html: `<div class="consents-description-container">
          <h3>Consent Description</h3>
          <div class="description-container text-left border rounded p-3 mb-3">
          ${description}
          </div>
          <hr>
          </div>`,
        showCancelButton: true,
        cancelButtonText: "Close",
        cancelButtonColor: "#6e84a3",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "px-0 booking-note",
        },
      });
    },
  },
};
</script>
<style lang="scss">
.consents-description-container {
  .description-container {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>
